<template>
  <v-container
    id="user-profile"
    fluid
    tag="section"
  >
    <base-header
      :title="$t('app.torneos.name')"
      link="Dashboard"
    />
    <v-row justify="center">
      <v-col
        cols="12"
      >
        <base-material-card color="primary">
          <template v-slot:heading>
            <v-row>
              <v-col class="flex-grow-1 flex-grow-0">
                <div class="display-2 font-weight-light">
                  {{ $t('app.torneos.title') }}
                </div>

                <div class="subtitle-1 font-weight-light">
                  {{ $t('app.torneos.subtitle') }}
                </div>
              </v-col>
              <v-col class="d-flex align-center flex-grow-0 flex-shrink-0">
                <xlsx-workbook v-if="excel && excel.length > 0 && excel[0].data.length > 0">
                  <xlsx-sheet
                    v-for="sheet in excel"
                    :key="sheet.name"
                    :collection="sheet.data"
                    :sheet-name="sheet.name"
                  />
                  <xlsx-download
                    :filename="excelName"
                  >
                    <v-btn
                      text
                      :disabled="excel.length == 0"
                    >
                      <v-icon>
                        mdi-file-excel-box
                      </v-icon>
                      &nbsp;
                      Exportar a Excel
                    </v-btn>
                  </xlsx-download>
                </xlsx-workbook>
                <v-spacer />
                <v-btn
                  dark
                  @click="openItemAdd"
                >
                  <v-icon
                    dark
                    left
                  >
                    mdi-plus-circle
                  </v-icon>

                  {{ $t('app.buttons.add') }}
                </v-btn>
              </v-col>
            </v-row>
          </template>

          <v-card-title>
            <v-row>
              <v-col>
                <v-select
                  v-model="filters.selected.items"
                  :items="filters.data.items"
                  item-text="nombre"
                  item-value="id"
                  :label="$t('app.sucursales.name')"
                  multiple
                  chips
                  deletable-chips
                  clearable
                  outlined
                  rounded
                  dense
                />
              </v-col>
              <v-col>
                <v-select
                  v-model="filters.selected.status"
                  :items="filters.data.status"
                  item-text="nombre"
                  item-value="id"
                  :label="$t('app.headers.status')"
                  multiple
                  chips
                  deletable-chips
                  clearable
                  outlined
                  rounded
                  dense
                />
              </v-col>
              <v-col>
                <v-text-field
                  v-model="filters.selected.query"
                  :label="$t('app.buttons.search')"
                  rounded
                  outlined
                  dense
                  append-icon="mdi-magnify"
                  clearable
                />
              </v-col>
            </v-row>
          </v-card-title>

          <v-data-table
            :headers="headers"
            :items="filteredItems"
            :items-per-page="10"
            :search="filters.selected.query"
          >
            <template v-slot:item.color="{ item }">
              <v-chip :style="`background-color: ${rgbaColor(item.color)}`" :dark="isDarkColor(rgbaColor(item.color))" :light="isLightColor(rgbaColor(item.color))">
                Color
              </v-chip>
            </template>
            <template v-slot:item.actions="{ item }">
              <v-col class="d-flex justify-end">
                <v-tooltip top>
                  <template v-slot:activator="{on,attrs}">
                    <v-btn
                      icon
                      v-bind="attrs"
                      @click="openManageTeams(item)"
                      v-on="on"
                    >
                      <v-icon>
                        mdi-account-multiple-plus
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>
                    {{ $t('app.general.manage') }}
                    {{ $t('app.equipos.name') }}
                  </span>
                </v-tooltip>

                <v-btn
                  icon
                  @click="openItemDetail(item)"
                >
                  <v-icon>
                    mdi-eye
                  </v-icon>
                </v-btn>

                <v-btn
                  icon
                  @click="openItemEdit(item)"
                >
                  <v-icon>
                    mdi-pencil
                  </v-icon>
                </v-btn>

                <v-btn
                  icon
                  @click="openItemDelete(item)"
                >
                  <v-icon>
                    mdi-delete
                  </v-icon>
                </v-btn>
              </v-col>
            </template>
          </v-data-table>
        </base-material-card>
      </v-col>
    </v-row>

    <torneos-add />
    <torneos-edit />
    <torneos-detail />
    <torneos-delete />
    <torneos-manage-teams />
  </v-container>
</template>

<script>
  import { XlsxDownload, XlsxSheet, XlsxWorkbook } from 'vue-xlsx'
  export default {
    name: 'Torneos',
    components: {
      TorneosAdd: () => import('./TorneosAdd'),
      TorneosEdit: () => import('./TorneosEdit'),
      TorneosDetail: () => import('./TorneosDetail'),
      TorneosDelete: () => import('./TorneosDelete'),
      TorneosManageTeams: () => import('./TorneosManageTeams'),

      XlsxWorkbook,
      XlsxSheet,
      XlsxDownload,
    },
    data () {
      return {
        headers: [
          {
            text: this.$t('app.headers.id'),
            value: 'id',
          },
          {
            text: this.$t('app.headers.name'),
            value: 'nombre',
          },
          {
            text: this.$t('app.headers.color'),
            value: 'color',
          },
          {
            text: this.$t('app.sucursales.single'),
            value: 'sucursal.nombre',
          },
          {
            text: this.$t('app.headers.start_date'),
            value: 'fecha_inicio',
          },
          {
            text: this.$t('app.headers.end_date'),
            value: 'fecha_fin',
          },
          {
            text: this.$t('app.headers.journey_quantity'),
            value: 'cantidad_jornadas',
          },
          {
            text: this.$t('app.headers.registration_fee'),
            value: 'costo_inscripcion',
          },
          {
            text: this.$t('app.headers.deposit'),
            value: 'fianza',
          },
          {
            text: '',
            value: 'actions',
            sortable: false,
          },
        ],
        items: [],
        filters: {
          data: {
            items: [],
            status: [
              {
                nombre: this.$t('app.general.active'),
                id: true,
              },
              {
                nombre: this.$t('app.general.inactive'),
                id: false,
              },
            ],
          },
          selected: {
            items: [],
            query: '',
            status: [],
          },
        },
        excel: [],
        excelName: this.$t('app.torneos.title') + ' - ' + this.$moment().format('YYYYMMDDHHmmss') + '.xlsx',
        excelHiddenColumns: [
          'id',
        ],
      }
    },
    computed: {
      filteredItems () {
        let items = this.items
        if (this.filters.selected.items.length > 0) {
          items = this.items.filter(item => this.filters.selected.items.includes(item.sucursal_id))
        }

        if (this.filters.selected.status.length > 0) {
          items = items.filter(item => this.filters.selected.status.includes(item.activo))
        }

        return items
      },
    },
    mounted () {
      this.init()

      EventBus.$on('reload-items', () => {
        this.init()
      })
    },
    methods: {
      async init () {
        this.toggleLoader()

        this.filters.data.items = await this.getBranches()
        this.items = await this.getTorneos()

        this.excel = [
          {
            name: this.$t('app.general.report'),
            data: this.prepareForExcel(this.items, this.headers, this.excelHiddenColumns),
          },
        ]

        this.toggleLoader()
      },
      openItemAdd () {
        EventBus.$emit('torneos-add')
      },
      openItemEdit (item) {
        EventBus.$emit('torneos-edit', item)
      },
      openItemDetail (item) {
        EventBus.$emit('torneos-detail', item)
      },
      openItemDelete (item) {
        EventBus.$emit('torneos-delete', item)
      },
      openManageTeams (item) {
        EventBus.$emit('torneos-gestionar-equipos', item)
      },
    },
  }
</script>

<style scoped>

</style>
